<template>
    <div class="form">
        <div class="background background_blue background_vacancy">
            <div class="background__image background__image_vacancy"></div>
        </div>

        <div class="wrapper" v-if="!isMobile || !$store.state.popup.id && (!isMobile || !$store.state.is_popup_shown)">
            <div class="content">
                <div class="content__container vacancyQuestionnaire">
                    <div class="vacancyQuestionnaire__title">{{ "a_title" | localize }}</div>

                    <form @submit.prevent>
                        <!-- ФИО и Должность -->
                        <div class="form__group">
                            <v-text-field
                                v-model="name"
                                @input="checkForm($v.name)"
                                @blur="nextElementfocus('name', 'position')"
                                @keyup.enter="$event.target.blur()"
                                :class="{ invalid: $v.name.$dirty && !$v.name.required }"
                                :label=" 'a_name' | localize"
                                :placeholder="'a_requeired' | localize"
                                :hint=" ($v.name.$dirty && !$v.name.required ? 'a_requeired' : 'empty') | localize"
                                ref="name"
                                outlined
                            ></v-text-field>

                            <v-select
                                v-model="position"
                                :items="positions"
                                :class="{ invalid: $v.position.$dirty && !$v.position.required }"
                                item-text="name"
                                item-value="value"
                                :label="'a_post' | localize"
                                :placeholder="'select' | localize"
                                :menu-props="{ offsetY: true }"
                                append-icon="icon-arrow-down"
                                ref="position"
                                outlined
                            ></v-select>
                        </div>

                        <!-- Зарплата / Телефон -->
                        <div class="form__group">
                            <v-text-field
                                v-model="salary"
                                class="input_number"
                                inputmode="numeric"
                                pattern="[0-9]*"
                                @blur="nextElementfocus('salary', 'phone')"
                                @keyup.enter="$event.target.blur()"
                                @keypress="onKeyPressHandler"
                                @keyup="onKeyUpPriceHandler('salary', salary)"
                                :class="{ invalid: $v.salary.$dirty && !$v.salary.required }"
                                :hint="($v.salary.$dirty && !$v.salary.required ?  'a_requeired' : 'empty') | localize"
                                :label="'a_salary' | localize"
                                :placeholder="'a_requeired' | localize"
                                append-icon="icon-₪"
                                ref="salary"
                                outlined
                            ></v-text-field>

                            <v-text-field
                                v-model="phone"
                                type="tel"
                                v-mask.hide-on-empty="phoneMask"
                                @input="checkForm($v.phone)"
                                class="v-input-phone"
                                :class="{invalid: $v.phone.$dirty && (!$v.phone.required || !$v.phone.minLength)}"
                                :label="'a_phone' | localize"
                                :placeholder="'a_requeired' | localize"
                                :hint="($v.phone.$dirty && !$v.phone.required ? 'a_requeired' : 'empty') | localize"
                                ref="phone"
                                outlined
                            ></v-text-field>
                        </div>

                        <!-- E-mail и Город -->
                        <div class="form__group">
                            <v-text-field
                                v-model="email"
                                type="email"
                                @input="checkForm($v.email)"
                                :class="{
                                    invalid:
                                    ($v.email.$dirty && !$v.email.required) ||
                                    ($v.email.$dirty && !$v.email.email),
                                }"
                                :label="'a_email' | localize"
                                placeholder="name@bankimonline.com"
                                :hint="hintArray(
                                    $v.email, [
                                    {rule: $v.email.required, message: 'form_reqiered_email'},
                                    {rule: $v.email.alpha, message: 'form_wrong_abc_email'},
                                    {rule: $v.email.email, message: 'form_wrong_email'}
                                    ]
                                ) | localize"
                                ref="email"
                                outlined
                            ></v-text-field>

                            <v-autocomplete
                                v-model="city"
                                :items="cities"
                                @change="checkForm($v.city)"
                                :class="{ invalid: $v.city.$dirty && !$v.city.required }"
                                item-text="name"
                                item-value="value"
                                cache-items
                                :label="'a_city' | localize"
                                :placeholder="'select' | localize"
                                ref="city"
                                outlined
                            ></v-autocomplete>
                        </div> 
                        
                        <!-- Резюме и письмо -->
                        <div class="form__group">
                            <div class="form__element aspirant__file" :class="{ invalid: ($v.resumeFile.$dirty && !$v.resumeFile.required) || ($v.resumeFile.$dirty && $v.resumeFile.$invalid) }" @click="loadFile">
                                <div class="aspirant__file_resume">{{ 'a_resume' | localize }}</div>
                                {{ 'a_resume_info' | localize }}
                                <div class="aspirant__file_load"><span class="icon-vector"></span>{{ 'a_load' | localize }}</div>
                                <input
                                    ref="downloadFile"
                                    @change="fileChanged($event)"
                                    type="file"
                                    name="filepath"
                                    accept="application/msword, application/pdf"
                                />
                            </div>

                            <v-textarea
                                v-model="text"
                                class="aspirant__textarea"
                                :class="{ invalid: $v.text.$dirty && !$v.text.required }"
                                :hint="($v.text.$dirty && !$v.text.required ? 'a_requeired' : 'empty') | localize"
                                :label="'a_cv' | localize"
                                :placeholder="'a_cv_info' | localize"
                                ref="text"
                                outlined
                                auto-grow
                            ></v-textarea>
                        </div>

                        <div class="error__container error__container_button">
                            <p class="error" v-if="error">{{ error | localize }}</p>
                            <p class="error" v-if="!success && check_form">{{ 'field_required' | localize }}</p>
                        </div>

                        <button type="button" class="button button__form" :class="{ button__form_disabled: !success }" @click="submitHandler()">
                            {{  'a_send' | localize }}
                        </button>
                    </form>
                </div>
            </div>
        </div>

        <div class="popup popup_active popup_info popup_lawyer" v-if="$store.state.popup.id">
            <div class="popup__overlay popup__overlay_active">
                <div class="popup__center" @click.self="$store.commit('togglePopupText')">
                    <div class="popup__window popup__window_blue">
                        <button class="close popup__close" @click.self="$store.commit('togglePopupText')"></button>
                        <div class="popup__logo"><router-link to="/" class="logo">BANKIM<span>ONLINE</span></router-link></div>
                        <div class="popup__scrollcontainer ">
                            <div class="popup__scrollcontainer_inner">
                                <TextBlock :id="29" />
                            </div>
                        </div>
                        <div class="button__container" @click="$store.commit('togglePopupText')">
                            <router-link type="button" class="button button__form" :to="{name: 'Vacancy'}">{{ 'v_tooltip2' | localize }}</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { email, required, minLength } from 'vuelidate/lib/validators'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'
import TextBlock from '@/components/Text.vue'

export default {
  name: 'Aspirant_Questionnaire',
  mixins: [formMixin, commonMixin, numberMixin],
  components: { TextBlock },
  props: {
    vacancy: { type: Number, required: true }
  },
  data: () => ({
    cities: [],
    positions: [],
    phoneMask: [/0/, /5|7/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
    menu: false,

    name: null,
    phone: null,
    email: null,

    city: null,
    position: null,
    salary: null,

    text: null,
    resume: null,
    resumeFile: null,
    error: null
  }),
  validations: {
    name: { required },
    phone: { required, minLength: minLength(12) },
    email: { required, email, alpha: val => /^[a-z]/i.test(val) },
    city: { required },
    position: { required },
    salary: { required },
    text: { required },
    resumeFile: { required, file_size_validation: (value) => {
        if (!value) return true
        let file = value
        return (file.size < 5000000)
      }
    }
  },
  created () {
    if (!this.vacancy) this.$router.push({name: 'Vacancy'})
  },
  mounted () {
    const localeName = 'name_' + this.$store.state.lang
    const cities = this.axios.get('cities?sort=population&projection=id,' + localeName)
    const positions = this.axios.get('vacancies/positions')

    this.axios.all([cities, positions]).then(this.axios.spread((...responses) => {
      this.cities = responses[0].data.map((e) => ({ name: e[localeName], value: e.id }))
      this.positions = responses[1].data
    })).catch(() => { 
      this.error = 'server_error'
      this.$scrollTo('.error__container', 300, { offset: -400 })
    })
  },
  methods: {
    loadFile() {
      this.$refs.downloadFile.click()
    },
    fileChanged(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.resumeFile = files[0];
    },
    nextElementfocus (current, next) {
      this.$v[current].$touch()
      if (!this.$v[current].$invalid) {
        this.$refs[current].blur()
        setTimeout(() => { this.$refs[next].focus() }, 100)
      }
    },
    submitHandler () {
      this.check_form = true

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      const formData = new FormData()
      formData.append('resume', this.$refs.downloadFile.files[0])
      formData.append('name', this.name)
      formData.append('phone', this.phone)
      formData.append('email', this.email)
      formData.append('city_id', this.city)
      formData.append('vacancy_id', this.vacancy)
      formData.append('salary', this.parseNumber(this.salary))
      formData.append('position', this.position)
      formData.append('text', this.text)

      // Сохранение в бд
      this.$store.commit('loading', true)
      this.axios.post('aspirants', formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
        this.$store.commit('togglePopupText', { id: 29 })
      }).catch((error) => {
        this.$store.commit('loading', false)
        this.error = error.response.data.error ?? 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }
  }
}
</script>

<style  scoped>
    .background__image.background__image_vacancy {
        opacity: 1;
        z-index: 1;
        position: relative;
        background-image: url("../../assets/images/common/vacancy/main.png");
    }
    .background__image.background__image_vacancy:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("../../assets/images/common/vacancy/overlay.png");
        background-position: center;
        background-size: contain;
        background-repeat: repeat-y;
    }
    
    #app.light-theme .background__image.background__image_vacancy:before {
        background-image: url("../../assets/images/common/vacancy/overlay_light.png");
    }
</style>
